import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { graphql, Link } from "gatsby";

// Local imports
import Layout from "src/layouts/LayoutWhiteHeader";
import StripPrimary from "src/components/generic/StripPrimary/StripPrimary";
import StripTitle from "src/components/generic/StripTitle/StripTitle";
import HowItWorks from "src/components/generic/HowItWorks/HowItWorks";
import StripsAlternatingContent from "src/components/generic/StripsAlternatingContent/StripsAlternatingContent";
import StripTwoColumnIconSmall from "src/components/generic/StripTwoColumnIconSmall/StripTwoColumnIconSmall";
import SEO from "src/components/generic/SEO/SEO";
import config from "data/SiteConfig";
import Tabs from "src/components/generic/Tabs/Tabs";

import { REPORT_DATA } from "src/routesConstants";

// Styles
// import styles from "./index.module.scss";

// Icons

const stripPrimary = {
	title: "Working with in-house teams",
	subTitle: (
		<React.Fragment>
			<p>Having good data is the foundation of marketing & SEO.</p>
			<p>
				But getting ready to use as you get larger is hard. It&apos;s
				slow, complex work which needs both domain expertise and
				engineering skill.
			</p>
			<p>
				We often end up pulling what we can from interfaces, getting
				stuck in the BI request queue and trying to cobble together the
				rest.
			</p>
			<p>
				We take on the responsibility for your data and let you get on
				with the actual work you need to do.
			</p>
		</React.Fragment>
	)
};

const inhouseAgreements = [
	{
		subTitle: "Get the most out of your data.",
		content: (
			<React.Fragment>
				<p>
					We can help you get a handle on your SEO data, tie together
					your sources and make it all easy to work with.
				</p>
				<p>
					You&apos;ll get more done, you&apos;ll get better insights
					and all the data will be yours, now and forever.
				</p>
				<p>
					Pictures are worth a thousand words and we hope reports are
					worth a couple thousand more. We&apos;ve anonymised some of
					our reports, so you can see the sort of thing that is
					possible with your data.
				</p>
				<p>
					But we have a lot more than this! Please get in touch and we
					can show you what is possible.
				</p>
			</React.Fragment>
		),
		secondContent: (
			<React.Fragment>
				<div className="reportLink">
					<h3 className="is-size-5">
						<a
							href="https://lookerstudio.google.com/u/0/reporting/6848ee68-c73c-40a5-99bb-90da18fc26d5/"
							target="blank"
							rel="noopener noreferrer"
						>
							Branded SERP Monitoring
						</a>
					</h3>
					<span>Monitor and control your brand image on Google.</span>
				</div>
				<div className="reportLink">
					<h3 className="is-size-5">
						<a
							href="https://lookerstudio.google.com/u/0/reporting/a2c4a21e-26a3-4cea-9348-d9d9d94c6535/"
							target="blank"
							rel="noopener noreferrer"
						>
							Rank Tracking
						</a>
					</h3>
					<span>Analyse rich rank tracking data.</span>
				</div>
				<div className="reportLink">
					<h3 className="is-size-5">
						<a
							href="https://lookerstudio.google.com/u/0/reporting/322d75af-be9f-48c7-9584-352d90d14dac/"
							target="blank"
							rel="noopener noreferrer"
						>
							Analytics Performance
						</a>
					</h3>
					<span>
						A base template for measuring SEO performance with
						analytics.
					</span>
				</div>
				<div className="reportLink">
					<h3 className="is-size-5">
						<a
							href="https://lookerstudio.google.com/u/0/reporting/cc80e9af-db4b-41e0-98a7-8ee29ab5d537/"
							target="blank"
							rel="noopener noreferrer"
						>
							Log Monitoring
						</a>
					</h3>
					<span>Monitor your logs for technical issues.</span>
				</div>
				<div className="reportLink">
					<h3 className="is-size-5">
						<a
							href="https://lookerstudio.google.com/u/0/reporting/88c1fbe6-9866-45d6-bbfd-23facace4474/"
							target="blank"
							rel="noopener noreferrer"
						>
							Core Web Vitals
						</a>
					</h3>
					<span>Track your domain and competitors CWV.</span>
				</div>
			</React.Fragment>
		)
	}
];

const propTypes = {
	data: PropTypes.shape().isRequired
};

const inhouseListStrength = [
	{
		subTitle: "It's hard to hire the right people.",
		content: (
			<React.Fragment>
				<p>
					Getting your SEO data means hiring people who have the right
					SEO experience and engineering experience.
				</p>
				<p>Hiring those people is hard and expensive.</p>
				<p>
					You&apos;ll find it far easier to put together a great team
					when all your data is sorted for you.
				</p>
				<p>
					And if you were lucky enough to get some of those people,
					they&apos;ll be able to do even more useful things now the
					data is handled.
				</p>
			</React.Fragment>
		)
	},
	{
		subTitle: "No more UI limits, sampling, cardinality. All your data.",
		content: (
			<React.Fragment>
				<p>
					We get a lot of fantastic free data sources in SEO, but when
					you get big the UI stops being useful.
				</p>
				<p>
					You run into all sorts of issues: sampling, cardinality,
					slow access, limits. We&apos;ll get all your data out and
					make sure you&apos;ve got it forever.
				</p>
			</React.Fragment>
		)
	},

	{
		subTitle: "Get going now. Not in 6 months time.",
		content: (
			<React.Fragment>
				<p>
					Working internally means working around the resource
					constraints of your business.
				</p>
				<p>
					BI and engineering resource can be limited and changes can
					take a long time to go through.
				</p>
				<p>
					We can help you bypass that and work with your BI &
					engineering team to get your data ready to go.
				</p>
			</React.Fragment>
		)
	}
];

const Index = ({ data }) => {
	// Set images
	inhouseListStrength[0].image = data.people1.childImageSharp.fluid;
	inhouseListStrength[1].image = data.sampling.childImageSharp.fluid;
	inhouseListStrength[2].image = data.gantt.childImageSharp.fluid;

	const crawlStrips = (
		<StripsAlternatingContent stripList={inhouseListStrength} />
	);
	const tabStripProblems = [
		{
			id: "problems",
			header: "How do we help?",
			content: crawlStrips
		}
	];

	// Set-up tab content.

	const title = "Working with in-house teams";
	const description =
		"You've got strategies to make, actions to change on your clients site, all while juggling communications and getting ready for the quarterly review. You don't have time to spend debugging a data issue for 4 hours.";
	return (
		<Layout>
			<Helmet title={config.siteTitle} />
			<SEO pageTitle={title} pageDescription={description} />
			<StripPrimary
				title={stripPrimary.title}
				subTitle={stripPrimary.subTitle}
				fullSized={true}
				isWhite={true}
				demoButton={true}
			/>
			<section>
				{/* <StripTitle title="What do we load?" /> */}
				{/* <StripsAlternatingContent
					stripList={agencyAgreementsStrip}
					classes="m-t-lg"
					noBotMargin={true}
				/> */}
				<section>
					<StripsAlternatingContent
						stripList={inhouseAgreements}
						classes="m-t-lg"
						noBotMargin={true}
					/>
				</section>
				<HowItWorks />
				<section>
					<StripTitle title="How do we help in-house teams?" />
					<Tabs tabData={tabStripProblems} />
				</section>
			</section>
		</Layout>
	);
};

Index.propTypes = propTypes;

export default Index;

// The graphql query made here will automatically be entered as the
// prop data in data
export const pageQuery = graphql`
	query InHouseQuery {
		# This gets the main image
		people1: file(
			relativePath: {
				eq: "images/pages/how_does_it_work/people_small.png"
			}
		) {
			childImageSharp {
				fluid(maxWidth: 501, maxHeight: 393) {
					...GatsbyImageSharpFluid
				}
			}
		}
		# Get us the main alternating images
		sampling: file(
			relativePath: { eq: "images/pages/use_cases/sampling_2.png" }
		) {
			childImageSharp {
				fluid(maxWidth: 354, maxHeight: 352) {
					...GatsbyImageSharpFluid
				}
			}
		}
		gantt: file(relativePath: { eq: "images/pages/use_cases/ganntt.jpg" }) {
			childImageSharp {
				fluid(maxWidth: 1046, maxHeight: 652) {
					...GatsbyImageSharpFluid
				}
			}
		}
	}
`;
