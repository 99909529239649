import React from "react";
import PropTypes from "prop-types";

import styles from "./StripTwoColumnIconSmall.module.scss";

const propTypes = {
	image: PropTypes.shape(),
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
		.isRequired,
	backgroundGrey: PropTypes.bool,
	fullSized: PropTypes.bool,
	contentList: PropTypes.arrayOf(
		PropTypes.shape({
			icon: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
				.isRequired,
			title: PropTypes.shape(),
			content: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
				.isRequired
		})
	).isRequired
};

const defaultProps = {
	fullSized: true,
	image: null,
	backgroundGrey: true
};

// ${styles.whatIDoBlock
function StripTwoColumnIconSmall({ title, contentList, backgroundGrey }) {
	let topPad = "p-t-xl";
	let botPad = "m-b-xl";
	// if (fullSized) {
	// 	demoButton = (
	// 		<a role="button" className="button is-outlined">
	// 			Book a demo
	// 		</a>
	// 	);

	// 	backgroundBlock = <div className={`${styles.backgroundRec}`} />;
	// 	topPad = "p-t-md";
	// 	botPad = "m-b-xl";
	// }
	const contentListRendered = [];

	for (let i = 0; i < contentList.length; i += 2) {
		const contentBlock1 = contentList[i];
		const contentBlock2 = contentList[i + 1];
		contentListRendered.push(
			<div className="columns">
				<div
					className={`column is-half-desktop is-half-tablet is-full-mobile`}
				>
					<div className="columns">
						<div
							className={`column is-narrow ${styles.iconSizing}`}
						>
							{contentBlock1.icon}
						</div>
						<div className="column is-vcentered">
							<h1 className="is-size-5">{contentBlock1.title}</h1>
							{contentBlock1.content}
						</div>
					</div>
				</div>
				<div
					className={`column is-half-desktop is-half-tablet is-full-mobile`}
				>
					<div className="columns">
						<div
							className={`column is-narrow ${styles.iconSizing}`}
						>
							{contentBlock2.icon}
						</div>
						<div className="column is-vcentered">
							<h1 className="is-size-5">{contentBlock2.title}</h1>
							{contentBlock2.content}
						</div>
					</div>
				</div>
			</div>
		);
	}

	const backgroundGreyStyle = backgroundGrey ? styles.backgroundGrey : "";

	return (
		<section
			className={`hero ${styles.backgroundHelper} ${backgroundGreyStyle} ${topPad}`}
		>
			<div className={`container maxContainer outerContainer ${botPad}`}>
				{contentListRendered}
			</div>
		</section>
	);
}

StripTwoColumnIconSmall.propTypes = propTypes;
StripTwoColumnIconSmall.defaultProps = defaultProps;

export default StripTwoColumnIconSmall;
